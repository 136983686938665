@import "../scss/_variables.scss";

.grid-component {
    height: auto;

    .grid-table {
        overflow-x: auto;
        width: 100%;
        position: relative;
        max-height: 29rem;

        table {
            border-collapse: separate;
            position: relative;
            text-align: left;
            border-spacing: 0;

            thead {
                tr {
                    top: 0;
                    left: 0;
                    position: -webkit-sticky;
                    position: sticky;
                    background-color: white;
                }
            }
        }

        table th {
            border-top: 1px solid #F2F2F2;
            border-bottom: 1px solid #F2F2F2;
            border-right: 1px solid #F2F2F2;
            border-left: 1px solid #F2F2F2;
        }

        thead {
            background-color: white;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 100;
            opacity: 1;

            th:not(:first-child) {
                width: 20rem;
            }
        }

        tbody {
            tr:last-child {
                td {
                    border-bottom: 1px solid #F2F2F2;
                }
            }
        }

        tr {
            th,
            td {
                border: 1px solid #F2F2F2;
                &:not(:first-child) {
                    max-width: 20rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .grid-pagination {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        .selected-items-count {
            font-size: 1rem;
            color: $fwc_blue;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .sortable {
        cursor: pointer;
    }

    .column-header {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    .column-filter {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    .select-row-checkbox-cell {
        padding-right: 0;
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .grid-checkbox {
        padding: 8px;
        background-color: white;
        margin-right: 0.5rem;
        border: 2px solid #ddd;
        border-radius: 0%;
        cursor: pointer;
        outline: none;
        appearance: none;

        &:checked {
            background-color: $fwc_blue;
            -webkit-appearance: initial;
            appearance: initial;
        }
    }

    .column-name {
        margin-right: 10px;
    }
}