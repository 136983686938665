@import "../../scss/_variables.scss";

.template-definitions-tab {
    text-transform: initial;

    .template-actions {
        display: flex;
        justify-content: space-around;
        width: 50%;

        i {
            cursor: pointer;
            font-size: 1.2rem;
        }

        .fa-edit {
            color: $fwc_blue;
        }
    }

    .template-definitions-actions {
        .btn-primary {
            transition: all 0.3s ease-in-out;
            margin-right: 1rem;
        }
    }
}

.delete-data-modal {
    .btn {
        max-width: 10rem;
        margin-right: 0.5rem;
        width: 100%;
    }
    .btn-danger {
        color: #d43f3a;
        &:hover {
            background-color: red;
            color: white;
        }
        &:disabled {
            background-color: #d9534f;
            border-color: #d43f3a;
            color: white;
        }
    }
}