@import "../../scss/_variables.scss";

.my-data-requests-container {
    width: 100%;
    background-color: white;
    padding: 1rem 1rem;
    margin: 0 auto;

    .page-headers {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;

        h2 {
            display: inline-block;
            color: $fwc_blue;
            text-transform: uppercase;
        }
    }

    .alert-messages {
        margin: 0 auto;
        width: 100%;
        padding: 0.5rem 0;
    }

    .requests-content {
        padding: 0 1rem;
    }

    .form-check-input:checked {
        background-color: $fwc_blue;
        border-color: $fwc_blue;
    }

    .requests-grid-toolbox {
        margin: 0 auto;
        background-color: white;
        padding-top: 0.5rem;
    }

    .view-request-btn {
        background-color: inherit;
        color: $fwc_blue;
        border: none;
        padding: 0.25rem 0rem;
        margin: 0.25rem 0;
        font-size: 0.7rem;
        font-weight: 600;
        cursor: pointer;
        transition: 0.3s;
        &:hover, &:focus {
            background-color: $fwc_blue;
            color: white;
        }

        i {
            font-size: 1.5rem;
            margin-right: 0.5rem;

            &:hover {
                cursor: pointer;
            }
        }
    }
}