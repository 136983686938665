@import "../../scss/_variables.scss";

.company-requests-rows-container {

    .company-requests-rows-container__header-row {
        text-transform: uppercase;
        margin: 0;
        padding: 0 1rem;
    }

    .btn {
        margin: 0 0.2rem;
        padding: 0.3rem 0.6rem;
    }

    .company-requests-rows-container__request-rows {
        max-height: 20rem;
        overflow-y: auto;
        margin-top: 0.25rem;
        padding: 0.5rem;
        background-color: #F5F5F5;

        .company-request-row {
            border: 1px solid $fwc_blue;

            .alerts-row {
                margin: 0;
                padding-bottom: 0;
            }
        }

        .row {
            padding: 0.5rem;
            margin: 0.5rem 0;
        }

        .alert {
            margin-bottom: 0;
        }
    }

    .row-buttons {
        flex: 0 0 20%;

        .btn {
            margin: 0 0.2rem;
        }
        .btn-danger {
            border-radius: 100%;
            border: 1px solid red;
            color: white;
            background-color: red;

            &:hover {
                background-color: white;
                color: red;
            }
        }
    }
}