@import "../../scss/_variables.scss";

.questionnaire-upload-modal {
    .btn {
        background-color: $fwc_light_blue;
        color: white;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;

        &:hover {
            background-color: darken($fwc_light_blue, 10%);
        }
    }

    .email-input-container {
        margin: 1em 0;
    }

    .email-label {
        display: block;
        margin-bottom: 0.5em;
        color: red;
    }

    .email-input {
        width: 100%;
        padding: 0.5em;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1em;
    }

    .file-input-container {
        margin-bottom: 1em;
    }

    .file-input {
        width: 100%;
        padding: 0.5em;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .password-input-container {
        margin: 1em 0;
    }

    .password-label {
        display: block;
        margin-bottom: 0.5em;
    }

    .password-input {
        width: 100%;
        padding: 0.5em;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1em;
        background-color: #f8f9fa;
        /* light gray */
    }

    .upload-questionnaire-instructions {
        margin-bottom: 1em;
        color: #666;
        font-size: 0.9rem;
    }

    .actions-buttons-container {
        display: flex;
        justify-content: flex-end;
    }

    .actions-buttons-container .btn {
        margin-left: 0.5em;
    }
}