.data-request-links {
    margin-top: 0.5rem;

    h6 {
        font-size: 0.75rem;
        text-transform: uppercase;
        line-height: 0.5rem;
        letter-spacing: 0.05rem;
        font-weight: 400;
    }

    a {
        width: max-content
    }
}