.ticker-name-conflict-modal {
    .sub-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: #6c757d;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    
    .conflicing-ticker-grid {
        width: 100%;
        margin: 0 auto;
    }

    .btn {
        max-width: 10rem;
        margin-right: 0.5rem;
        width: 100%;
    }
}