@import "../scss/_variables.scss";

$container-height: 42rem;
$font-family: Arial, sans-serif;

$primary-button-color: lighten($fwc_blue, 10%);
$secondary-button-color: lighten($primary-button-color, 20%);

.page-width {
    font-family: $font-family;
    color: $fwc_blue;

    .survey-participant-console-container {
        background-color: white;
        padding: 2rem;
        margin-bottom: 2rem;
        min-height: $container-height;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(132, 124, 124, 0.1);

        .title-text {
            font-size: 2rem;
            margin-bottom: 1rem;

            .hello-text {
                color: $fwc_blue;
            }

            .participant-name-text {
                color: $fwc_light_blue;
            }
        }

        .welcome-text {
            font-size: 1.2rem;
            margin-bottom: 1.25rem;
        }

        .survey-participant-console-content {
            position: relative;
            .section {
                margin-bottom: 1.25rem;

                .section-heading {
                    font-size: 1.4rem;
                    color: $fwc_light_blue;
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                }

                .btn {
                    display: inline-block;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    font-size: 1rem;
                    cursor: pointer;
                    margin-bottom: 1rem;
                    margin-right: 1rem;
                }

                .btn-primary {
                    padding: 0.5rem 1rem;
                    background-color: $primary-button-color;

                    &:hover {
                        background-color: darken($primary-button-color, 5%);
                    }
                }

                .btn-secondary {
                    padding: 0.25rem 0.75rem;
                    background-color: $secondary-button-color;

                    &:hover {
                        background-color: lighten($secondary-button-color, 10%);
                    }
                }

                .tooltip-container {
                    position: relative;
                    display: inline-block;
                }

                .tooltip-text {
                    visibility: hidden;
                    width: 10rem;
                    background-color: #555;
                    color: #fff;
                    text-align: center;
                    padding: 5px 0;
                    border-radius: 6px;
                    position: absolute;
                    z-index: 1;
                    bottom: 125%;
                    left: 50%;
                    margin-left: -60px;
                    opacity: 0;
                    transition: opacity 0.3s;
                }

                .tooltip-container:hover .tooltip-text {
                    visibility: visible;
                    opacity: 1;
                }
            }

            p,
            ul {
                font-size: 1.2rem;
                line-height: 1.5;
            }

            ul {
                padding-left: 2rem;

                li {
                    margin-bottom: 1rem;
                }
            }

            .link {
                color: darken($fwc_light_blue, 10%);
                text-decoration: underline;
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                }
            }

            .questionnaire-access {
                p {
                    margin-bottom: 1rem;
                }
            }

            .thank-you-text {
                position: absolute;
                width: 100%;
                font-size: 1rem;
                color: $fwc_blue;
                font-weight: normal;
                text-align: center;
                margin-top: 5rem;
                padding: 0.5rem;
                border-top: 1px solid $fwc_blue;
            }
        }
    }

    @media (max-width: 1024px) {

        // iPad Pro and smaller
        .survey-participant-console-container {
            padding: 1rem;
            margin-bottom: 1rem;

            .thank-you-text {
                font-size: 0.8rem;
                margin-top: 0;
            }
        }

        .title-text {
            font-size: 1.5rem;
        }

        .welcome-text {
            font-size: 1rem;
        }

        .section {
            .section-heading {
                font-size: 1.2rem;
            }
        }
    }

    @media (max-width: 768px) {

        // Average laptop screen and smaller
        .survey-participant-console-container {
            padding: 0.5rem;
            margin-bottom: 0.5rem;
            position: relative;

            p,
            ul {
                font-size: 1rem;
                line-height: 1.2;
            }

            ul {
                padding-left: 1rem;

                li {
                    margin-bottom: 0.5rem;
                }
            }

            .thank-you-text {
                font-size: 0.8rem;
                margin-top: 0;
            }
        }

        .title-text {
            font-size: 1.2rem;
        }

        .welcome-text {
            font-size: 0.9rem;
        }

        .section {
            margin-bottom: 1rem;

            .section-heading {
                font-size: 1rem;
            }
        }
    }
}