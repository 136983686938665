.survey-data {
    width: 100%;
    background-color: white;
    padding: 1rem 1rem;
    margin: 0 auto;
    
    .btn {
        max-width: 12rem;
        margin-right: 0.5rem;
        width: 100%;
    }

    .survey-actions-container {
        margin-top: 1rem;

        .survey-actions {
            display: inline-flex;
            width: 100%;
        }
    }

    .survey-data-grid-container {
        margin-top: 1rem;
    }

    .alert {
        margin-top: 1rem;
    }
}