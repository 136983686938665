.delete-confirmation-button {
    .btn {
        padding: 0.25rem;
    }

    .btn-danger {
        background-color: white;
        color: red;

        &:hover {
            background-color: red;
            color: white;
        }
    }
}