@import 'scss/_variables.scss';

:root {
  --amplify-primary-color: rgb(0, 147, 201) !important;
  --amplify-primary-contrast: white;
  --amplify-components-button-primary-hover-background-color: $fwc_light_blue;
  --amplify-primary-shade: $fwc_light_blue;
  --amplify-primary-tint: $fwc_blue;
  --amplify-components-tabs-item-active-color: $fwc_blue;
  --amplify-components-tabs-item-active-border-color: $fwc_blue;
}

.amplify-button[data-variation='primary'] {
  background-color: $fwc_blue !important;
  color: white !important;
  border-radius: 0;
}

.amplify-button--link {
  color: #0071CE !important;
}

.amplify-button--link:active {
  background-color: #fff !important;
}

.amplify-button--link:hover {
  background-color: #fff !important;
}

.amplify-tabs-item[data-state=active] {
  color: $fwc_blue !important;
  border-color: $fwc_blue !important;
}

.amplify-input {
  border-radius: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E7E9EB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  max-width: 85%;
  margin: 0 auto;
}

.page-width {
  max-width: 85%;
  margin: 1rem auto;
  padding-top: 7rem;
  min-height: 80vh;
}

@media only screen and (max-width: 768px) {
  .page-width {
    max-width: 90%;
  }

  .navbar {
    max-width: 90%;
  }
}

@media only screen and (max-width: 576px) {
  .page-width {
    max-width: 95%;
  }

  .navbar {
    max-width: 95%;
  }
}

.toolbar-btn {
  margin-right: 1rem;
}

.toolbar {
  padding-top: 1rem;
  border-radius: 0;
}

.toolbar-body {
  padding: 0 1.25rem;
}

.select-list-option {
  padding: 0.2rem;
}

@media only screen and (max-width: 1130px) {
  .page-width {
    max-width: 95%;
  }
}

button {
  text-transform: uppercase;
}

.btn {
  padding: .375rem 0;
  background-color: white;
  color: #000;
  border-radius: 0%;
  background-size: 100% 0;
  font-weight: 600;
  color: white;
  border: none;
}

.btn.collapsible-title {
  padding: .375rem 2.75rem;
}

.btn:hover {
  transition: all 150ms ease-in-out 0ms;
}

.btn-primary {
  background-color: $fwc_blue;

  &:disabled {
      background-color: #ccc;
      outline: $fwc_blue solid 1px;
  }

  &:hover {
      background-color: darken($fwc_blue, 5%);
  }

  &:focus {
      background-color: darken($fwc_blue, 5%);
  }
}

.btn-secondary {
  background-color: $fwc_light_blue;

  &:disabled {
      background-color: #ccc;
      outline: $fwc_blue solid 1px;
  }

  &:hover {
      background-color: darken($fwc_light_blue, 5%);
  }

  &:focus {
      background-color: darken($fwc_light_blue, 5%);
  }
}

amplify-authenticator {
  text-transform: uppercase;
}

amplify-sign-out {
  background-size: 100% 0;
}

button>i {
  padding-left: 0.4rem;
}

label {
  font-size: 0.75rem;
  margin-bottom: .5rem;
  text-transform: uppercase;
  line-height: 0.5rem;
  letter-spacing: 0.05rem;
}

.form-control, .form-select {
  border: 1px solid #56585a;
  border-radius: 0;
  font-size: 1rem;
}

.form-control:focus, .form-select:focus {
  border-color: #000812;
  box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 21%);
}

.form-group textarea {
  min-height: 8rem;
}

.form-group input {
  min-height: 3rem;
}

.modal-header {
  text-transform: uppercase;
}

.modal-title {
  text-transform: uppercase;
  text-align: center;
}

.drop-zone-text {
  margin: 2rem;
  cursor: pointer;
}

.spinner-border {
  display: block;
  position: fixed;
  top: calc(50% - 29px);
  right: calc(50% - 29px);
  color: #00253c;
}

.text-center {
  width: 100%;
  position: relative;
  height: 100%;
}

.backdrop {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 110vh;
  z-index: 10000;
  background-color: rgb(0, 0, 0, 0.2);
  overflow: hidden;
}

.spinner-blue {
  border-top-color: $fwc_blue;
  border-right-color: $fwc_blue;
  border-bottom-color: $fwc_blue;
  border-left-color: transparent;
}
