@import "../../scss/_variables.scss";

.survey-submissions-container {
    width: 100%;
    background-color: white;
    padding: 1rem 1rem;
    margin: 0 auto;

    .alert-messages {
        margin: 0 auto;
        width: 100%;
        padding: 0.5rem 0;
    }

    .submissions-content {
        padding: 0 1rem;

        .pagination-info {
            a {
                font-weight: 400;

                &:hover {
                    color: $fwc_blue;
                    background-color: white;
                }
            }
        }
    }

    .btn {
        width: 2rem;
        margin: 0.25rem
    }

    button>i {
        padding-left: 0rem;
    }
}