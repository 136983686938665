@import "../../scss/_variables.scss";

.billing-data-container {
    .btn {
        padding: 0.3rem 0.6rem;
        margin-right: 0.5rem;
    }

    .search-buttons {
        margin-top: 0.5rem;
    }

    .edit-record {
        display: inline-flex;
        justify-content: center;
        align-content: space-between;
        margin-top: 0.1rem;
        padding: 0 0.5rem;

        .btn {
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }

        i {
            font-size: 1.5rem;
            margin-right: 0.5rem;

            &:hover {
                cursor: pointer;
            }
        }

        .fa-edit {
            color: $fwc_blue;
        }
    }

}