@import "../../scss/_variables.scss";

.navbar-container {
    min-height: 6rem;
    color: $fwc_blue;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);

    .navbar-logo {
        display: flex;
        align-items: center;

        .navbar-logo-icon {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.5rem;
        }

        .navbar-logo-text {
            font-size: 1.5rem;
        }
    }

    .navbar-links {
        display: flex;
        align-items: center;

        .navbar-link {
            margin: 0 0.5rem;
            padding: 0.5rem;

            &:hover {
                background-color: $fwc_blue;
            }
        }
    }

    .main-navbar-actions {
        display: inline-flex;
        justify-content: space-between;
        text-transform: uppercase;
        padding-bottom: 0.75rem;

        .main-templates {
            display: inline-flex;
            justify-content: spaces-between;
            text-transform: uppercase;
        }

        .nav-item {
            background-color: white;
            color: $fwc_light_blue;
            z-index: 1;

            .nav-link {
                padding: 0.2rem 0.5rem;
                text-decoration: none;
                font-weight: 600;
                color: inherit;
            }

            .dropdown-menu {
                background-color: white;
                color: $fwc_blue;
                margin-right: 1rem;
                z-index: 1;
                border-radius: 0;

                .dropdown-item {
                    &:hover {
                        background-color: var(--bs-dropdown-link-hover-bg);
                    }

                    &:active {
                        background-color: var(--bs-dropdown-link-hover-bg);

                    }
                }
            }

            .active {
                background-color: $fwc_blue;
                color: white;
            }

            a {
                text-decoration: none;
                font-weight: 600;
                color: inherit;
            }
        }
    }

    .user-actions-area {
        padding-top: var(--bs-navbar-brand-padding-y);
        padding-bottom: 0.5rem;

        .user-actions {
            display: inline-flex;
            width: 100%;
            justify-content: flex-end;

            .fa {
                font-size: 1.5rem;
                color: $fwc_light_blue;
                padding: 0.5rem;
                margin: 0;

                &:hover {
                    cursor: pointer;
                }
            }

            .action {
                .btn {
                    border: none;
                    border-radius: 100%;
                    outline: none;
                    margin-right: 0.25rem;
                    color: $fwc_light_blue;
                    background-color: #fff;

                    &:hover, &:focus {
                        color: $fwc_light_blue;
                        background-color: #fff;
                    }
                }
            }
        }

        .user-name-display {
            color: $fwc_blue;
            font-weight: 500;
            margin-right: 0.5rem;
            text-transform: uppercase;
        }

    }
}