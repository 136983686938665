.client-metadata-tab {
    .upload-client-metadata {
        .btn-upload {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-right: 0.5rem;
        }

        .btn-refresh{
            padding-left: 0.25rem;
            padding-right: 0.5rem;
            border-radius: 100%;
        }
    }
}