@import "../scss/_variables.scss";

.admin-console {
    .nav-item {
        color: $fwc_blue;
        text-transform: uppercase;

        .nav-link {
            color: $fwc_blue;
            font-weight: 600;

            &:hover {
                color: white;
                background-color: $fwc_blue;
            }

            .active {
                &:hover {
                    background-color: $fwc_blue;
                    color: white;
                }
            }
        }
    }

    .main-content-area {
        background-color: white;
        padding: 1rem;
        margin: 0 1px;

        .template-definitions-tab {
            .template-definitions-actions {
                margin-bottom: 1rem;

                button {
                    padding: 0.5rem;
                }
            }
        }
    }
}