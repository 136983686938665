@import "../scss/_variables.scss";

.explore-data {
    color: black;

    .btn {
        margin-right: 0.5rem;
        width: 100%;
        color: #fff;
        border: none;
        outline: none !important;
    }

    .btn-primary {
        background-color: $fwc_blue;

        &:disabled {
            background-color: #ccc;
            outline: $fwc_blue solid 1px;
        }

        &:hover {
            background-color: darken($fwc_blue, 5%);
        }

        &:focus {
            background-color: darken($fwc_blue, 5%);
        }
    }

    .btn-secondary {
        background-color: $fwc_light_blue;

        &:disabled {
            background-color: #ccc;
            outline: $fwc_blue solid 1px;
        }

        &:hover {
            background-color: darken($fwc_light_blue, 5%);
        }

        &:focus {
            background-color: darken($fwc_light_blue, 5%);
        }
    }

    .all-companies-btn {
        background-color: $fwc_light_blue;
        margin-top: 2rem;
        height: 2.5rem;
        border-radius: 2rem;
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        max-width: -moz-fit-content;
        max-width: fit-content;

        &:hover {
            background-color: darken($fwc_light_blue, 5%);
        }
    }

    .explore-data__inputs {
        background-color: #fff;
        margin: 0 auto;
        padding: 1rem;

        .row-stat {
            padding: 10px 0 0;
            margin-left: 0.5rem;
            display: inline-flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 0.8rem;
        }

        .row-stat:first-of-type {
            margin-left: 0;
        }

        .row-stat_title {
            font-weight: 700;
        }

        .tickers-input {
            .ticker-base-input {
                &::after {
                    .company-lookup-result {
                        display: none;
                    }
                }
            }

            .expand-tickers-view-btn {
                margin: 0;
                background-color: #f8f9fa;
                color: $fwc_blue;
                border: none;
                padding-right: 0.5rem;
                cursor: pointer;
                outline: none !important;

            }

            .company-lookup {
                margin-bottom: 10px;
                text-transform: uppercase;
                font-size: 15px;

                .ticker-link {
                    color: $fwc_light_blue;
                    text-decoration: none;
                    font-weight: 700;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .fa {
        cursor: pointer;
    }

    .form-control,
    .form-select {
        height: 3.5rem;
    }

    .ticker-base-input {
        text-overflow: ellipsis;
    }

    .explore-data__header {
        margin: 0 auto;
        color: $fwc_blue;
    }
}

.toolbar-actions {
    display: flex;
    margin-top: 1rem;
}

@media screen and (max-width: 768px) {
    .explore-data__inputs {
        padding: 0.5rem;
    }

    .toolbar-actions {
        flex-direction: column;
        align-items: flex-start;
    }

    .explore-data__header {
        margin: 0;
    }

    .ticker-base-input {
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .explore-data__inputs {
        padding: 1rem 0.5rem 0 0.5rem;
    }

    .toolbar-actions {
        flex-direction: row;
        align-items: flex-start;
    }

    .explore-data__header {
        margin: 0;
    }

    .ticker-base-input {
        width: 100%;
    }
}