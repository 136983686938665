.search-results {
    border-color: #000812;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 10%);
    background-color: white;
    z-index: 10;
    position: absolute;
    box-sizing: border-box;
    max-height: 20rem;
    width: 29rem;
    border: solid 1px #eee;
    overflow: scroll;

    .search-result {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        margin: 10px 0px 0px 5px;
        padding: 0;
        cursor: pointer;

        &:hover {
            background-color: #eee;
        }
    }

    .search-result-button {
        background-color: white;
        border: none;
        width: 100%;
        cursor: pointer;
        text-align: left;
        padding: 4px;
    }

    .selected {
        background-color: #eee;
    }
}