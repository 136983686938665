.ticker-lookup-modal {
    .btn {
        max-width: 10rem;
        margin-right: 0.5rem;
        width: 100%;
    }

    .selected-tickers-container {
        margin-bottom: 0.5rem;

        .selected-tickers {
            min-height: 3rem;
            background-color: #d3d3d3;
            padding: 0.5rem;
            border-radius: 5px;

            .badge {
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                cursor: pointer;
            }
        }

        .clear-all-tickers {
            .clear-all-tickers-link {
                color: #000;
                font-size: 15px;
                text-transform: uppercase;
                text-decoration: none;
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .ticker-display-pannel {

        .company-lookup-results {
            border-color: #000812;
            box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 10%);
            background-color: white;
            z-index: 10;
            position: absolute;
            box-sizing: border-box;
            width: 30rem;
            border: solid 1px #eee;
            padding: 10px;
            overflow: scroll;

            .company-lookup-result {
                display: flex;
                align-items: center;
                padding: 6px 10px;
                cursor: pointer;

                &:hover {
                    background-color: #eee;
                    padding: 5px;
                }

                .company-lookup-result__ticker {
                    font-weight: 500;
                    margin: 0 0.5rem;
                }

                .company-lookup-result__name {
                    font-size: 0.95rem;
                }
            }

            .copy-value-button {
                display: flex;
                align-items: center;
                padding: 6px 10px;
                cursor: pointer;

                &:active {
                    transform: translateY(3px);
                }
            }
        }
    }

    .ticker-lookup-error-alert {
        margin-top: 0.5rem;
    }

}