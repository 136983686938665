@import "../scss/_variables.scss";

.grid-container {
  background-color: white;
  block-size: 100%;
  min-height: 36rem;
  max-block-size: calc(100vh - 100px);

  >.rdg {
    flex: 1;
  }

  .form-check-input:checked {
    background-color: $fwc_blue;
    border-color: $fwc_blue;
  }

  .grid-tool-bar {
    .btn {
      padding: 0.25rem;
      width: 2.5rem;
      margin-right: 0.2rem;

      .fa {
        font-size: 1.25rem;
        padding: 0.25rem;
      }
    }
  }
}

.filter-container {
  .filter-cell {
    line-height: 35px;
    padding: 0;

    >div {
      padding: 0 11px ;

      &:first-child {
        border-block-end: 1px solid var(--rdg-border-color);
      }
    }
  }
}

.filter-renderer {
  inline-size: 100%;
  height: 2rem;
  padding: 4px;
  font-size: 14px;
  margin: 0.75rem 0;
}