@import "../scss/_variables.scss";

.download-history-console {

    .row-actions {
        display: inline-flex;
        justify-content: center;
        align-content: space-between;
        margin-top: 0.1rem;
        padding: 0 0.5rem;

        .btn {
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }

        i {
            font-size: 1.5rem;
            margin-right: 0.5rem;

            &:hover {
                cursor: pointer;
            }
        }

        .fa-edit, .fa-save {
            color: $fwc_blue;
        }
    }
    .nav-item {
        color: $fwc_blue;
        text-transform: uppercase;
        .nav-link {
            color: $fwc_blue;
            &:hover {
                color: white;
            }
        }
        .active {
            &:hover {
                color: $fwc_blue;
            }
        }
    }

    a {
        color: $fwc_blue;
        font-weight: 600;

        &:active {
            color: $fwc_blue;
        }

        &:hover {
            background-color: $fwc_blue;
            color: white;
        }
    }

    .main-content-area {
        background-color: white;
        padding: 1rem;
        margin: 0 1px;

        .template-definitions-tab {
            .template-definitions-actions {
                margin-bottom: 1rem;

                button {
                    padding: 0.5rem;
                }
            }
        }
    }
}