@import "../../scss/_variables.scss";

.main-panel {
    background-color: white;
    padding: 1rem;

    .divider {
        margin-bottom: 1rem;
        border: 1px solid #e0e0e0;
    }

    h4 {
        color: $fwc_blue;
        text-transform: uppercase;
    }

    .section {
        margin-bottom: 1rem;
        padding: 0 0.5rem;

        .title-section {
            color: $fwc_blue;
            cursor: pointer;
            margin: 1rem 0;

            .title {
                font-size: 1.25rem;
                font-weight: 500;
                display: inline;
                padding-right: 0.75%;
            }
        }

        .filters {
            background-color: lightgrey;
            padding: 0.5rem 0.75rem 0 0.75rem;

            .actions {
                .btn {
                    margin: 0;
                }
            }
        }

        .variable-col-section {
            max-height: 20rem;
            overflow-y: auto;
            border: 10px solid lightgrey;
        }

        @media (min-width: 768px) {
            .variable-col-section {
                max-height: 30rem;
            }
        }

        .def-row-operations {
            .btn {
                padding: 0.3rem 0.6rem;
                margin: 0 0.2rem;
            }

            .btn-danger {
                .fa-trash {
                    color: red;
                }

                &:hover {
                    background-color: red;

                    .fa-trash {
                        color: white;
                    }
                }
            }
        }

        .sheet-actions {
            margin-top: 1.55rem;
        }
    }

    .alerts {
        margin-top: 1rem;

        .alert {
            margin-bottom: 0.5rem;
        }
    }

    .actions-and-alerts {
        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .actions {
        display: inline;

        .btn {
            padding: 0.25rem 0.5rem;
            margin: 0.25rem 0.25rem;
        }
    }
}