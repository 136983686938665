.template-companies-grid-modal {
    .btn {
        max-width: 10rem;
        margin-right: 0.5rem;
        width: 100%;
    }
    .companies-grid {
        width: 100%;
        margin: 0 auto;
    }
}