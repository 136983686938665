@import "../scss/_variables.scss";

.pagination-info {
    .paginator-item {
      border-radius: 0%;
  
      .page-link {
        border-radius: 0%;
        color: $fwc_light_blue;
      }
    }
  
    .active {
      .page-link {
        background-color: $fwc_light_blue;
        color: white;
      }
    }
  
    .page-size {
      .page-link {
        padding: 0;
      }
    }

    .result-count {
      color: $fwc_blue;
      font-weight: 500;
      text-transform: uppercase;
    }
  }