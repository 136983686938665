@import "../../scss/_variables.scss";

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.c-multi-select-dropdown {
    font-family: 'Roboto', sans-serif;
    position: relative;
    border: 1px solid black;
    height: 3.5rem;

    &:hover {
        .c-multi-select-dropdown__options {
            display: block;
        }
    }

    &:focus {
        border-color: #000812;
        box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 21%);

        .c-multi-select-dropdown__options {
            display: block;
        }
    }

    &:focus-visible {
        border-color: #000812;
        box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 21%);
        outline: none;
    }

    .c-multi-select-dropdown__selected {
        font-size: 14px;
        height: inherit;
        padding: 0 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-y: auto;
    }

    .c-multi-select-dropdown__options {
        background-color: white;
        z-index: 10;
        position: absolute;
        box-sizing: border-box;
        left: 0;
        width: 100%;
        list-style: none;
        padding-left: 0px;
        border: solid 1px #eee;
        padding: 5px 0px;
        height: 11rem;
        overflow: scroll;
    }

    .c-multi-select-dropdown__search-input {
        border: 0;
        padding-left: 0.2rem;
        outline: none;
    }

    .c-multi-select-dropdown__option {
        display: flex;
        align-items: flex-start;
        padding: 6px 10px;
        cursor: pointer;

        input[type="checkbox"] {
            cursor: pointer;
            height: 1.25rem;
            width: 1.25rem;
            border-radius: 0%;
            border: 3px solid #ddd;
            appearance: none;

            &:checked {
                background-color: $fwc_blue;
            }

            &::after {
                padding: 5px;
                left: 9px;
                top: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
            }

            .c-multi-select-dropdown__option-checkbox {
                size: 1rem;
            }
        }

        .c-multi-select-dropdown__option-label {
            font-size: 1rem;
            display: flex;
            align-self: baseline;
        }

        &:hover {
            background-color: white;
        }

        &:focus {
            background-color: white;
        }

        &-checkbox {
            margin-right: 6px;
        }
    }
}