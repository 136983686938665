@import "../../scss/_variables.scss";

.data-request-submission {

    .data-request-submission-container {
        background-color: white;
        padding: 1rem;
    }

    .btn {
        margin: 0 0.2rem;
        padding: 0.3rem 0.6rem;
    }

    .header-title {
        text-transform: uppercase;
        color: $fwc_blue;
    }

    .data-request-inputs {
        margin: 1rem 1rem 0 1rem;
    }

    .data-request-buttons {
        margin: 1rem 1rem 0 1rem;
        display: flex;
        justify-content: flex-end;
    }

    .company-requests {
        margin: 1rem 1rem 0;

        .company-requests__header {
            display: flex;
            justify-content: space-between;

            .company-requests__header__title {
                display: inline-flex;

                .company-requests__header__title__badge {
                    margin-left: 0.5rem;

                    .bg-primary {
                        background-color: $fwc_blue !important;
                    }
                }
            }

            .company-requests__header__add-button {
                display: inline-flex;
                .btn {
                    padding: 0;
                    border: 0;
                    background-color: white;

                    &:hover {
                        border: 0;
                    }
                }

                .fa-plus-circle {
                    color: white;
                    background-color: $fwc_blue;
                    font-size: 2.5rem;
                    cursor: pointer;
                    border-radius: 100%;
                    border: 1px solid $fwc_blue;
                    padding: 0;

                    &:hover {
                        color: $fwc_blue;
                        background-color: white;
                        border-radius: 100%;
                        border: 1px solid white;
                    }
                }
            }
        }
    }

    .btn-danger {
        color: red;

        &:hover {
            background-color: red;
            color: white;
        }
    }

    .row-buttons {
        flex: 0 0 20%;

        .btn {
            margin: 0 0.2rem;
        }
        .btn-danger {
            border-radius: 100%;
            border: 1px solid red;
            color: white;
            background-color: red;

            &:hover {
                background-color: white;
                color: red;
            }
        }
    }
}