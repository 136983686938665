@import "../../scss/_variables.scss";

.grid-container {
  display: flex;
  flex-direction: column;
  block-size: 100%;
  gap: 10px;
  max-block-size: calc(100vh - 100px);
  overflow: hidden;

  .grid-tool-bar {
    .btn {
      background-color: $fwc_light_blue;
      padding: 0.25rem;
      width: 2.5rem;
      margin-right: 0.2rem;
      border: none;

      .fa {
        color: white;
        font-size: 1.25rem;
        padding: 0.25rem;
      }

      &:hover, &:focus {
        background-color: darken($fwc_light_blue, 5%);
      }
    }

    .rounded-pill {
      margin-left: 0.5rem;
      padding: 0.5rem;
    }

    .bg-secondary {
      color: $fwc_light_blue;
      background-color: white !important;
      border: 2px solid $fwc_light_blue !important;

      &:hover, &:focus {
        background-color: $fwc_light_blue !important;
        color: white;
      }
    }

    .bg-primary {
      background-color: $fwc_blue !important;
      border: 2px solid $fwc_blue !important;

      &:hover {
        background-color: darken($fwc_blue, 5%) !important;
      }
    }

    .available-sheets, .grid-tools {
      margin-top: 0.5rem;
      display: flex;
      justify-content: start;
      color: white;
      border: 0;
      padding-left: 0.25rem;
    }
  }

  >.rdg {
    flex: 1;
  }

  .rdg-row-odd {
    background-color: darken(#f8f9fa, 5%);
  }

  .grid {
    margin-bottom: 2rem;
    font-family: sans-serif, monospace;
    min-height: 36rem;
  }
}

@media (min-width: 1024px) {
  .grid-container {
    max-block-size: calc(100vh - 150px);
  }
}

.expanded-grid-modal {

  .btn {
    background-color: $fwc_light_blue;
    color: white;
    border: 0;
    border-radius: 0;
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;

    &:hover {
      background-color: darken($fwc_light_blue, 5%);
    }

    &:disabled {
      background-color: #ccc;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
    width: 100%;
  }

  .modal-body {
    padding: 0.5rem 1.5rem;
    overflow-y: clip;
  }

  .modal-footer {
    border-top: 1px solid;
  }

  .modal-title {
    color: "white";
  }

  .modal {
    align-items: center;
    justify-content: center;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.filter-container {
  .filter-cell {
    line-height: 35px;
    padding: 0;
  }
}

.filter-renderer {
  inline-size: 100%;
  height: 2rem;
  padding: 4px;
  font-size: 14px;
  margin: 0.75rem 0;
}