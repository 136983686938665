@import "../../scss/_variables.scss";

.edit-data-request-container {

    .btn {
        margin: 0 0.2rem;
        padding: 0.3rem 0.6rem;
    }

    .company-requests-header {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        margin: 1rem 0;


        .company-requests-header__title {
            display: inline-flex;
            color: $fwc_blue;

            .company-requests__header__title__badge {
                margin-left: 0.5rem;

                .bg-primary {
                    background-color: $fwc_blue !important;
                }
            }
        }

        .company-requests__header__add-button {
            display: inline-flex;

            .btn {
                padding: 0;
                border: 0;
                background-color: white;

                &:hover {
                    border: 0;
                }
            }

            .fa-plus-circle {
                color: white;
                background-color: $fwc_blue;
                font-size: 2.5rem;
                cursor: pointer;
                border-radius: 100%;
                border: 1px solid $fwc_blue;
                padding: 0;

                &:hover {
                    color: $fwc_blue;
                    background-color: white;
                    border-radius: 100%;
                    border: 1px solid white;
                }
            }
        }
    }

    .company-requests-grid-container {
        height: auto;
    }
}