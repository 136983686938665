.view-data-request-modal {
    display: inline-flex;
    justify-content: center;
    align-content: space-between;

    .btn {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    i {
        font-size: 1.5rem;
        margin-right: 0.5rem;

        &:hover {
            cursor: pointer;
        }
    }
}